.app-container {
  text-align: center;
}

.app-header {
  background-color: #8e7cc3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  padding: 5px;
}

.app-header2 {
  background-color: #b4a7d6;
  border-radius: 5px;
  padding: 3px;
}

.app-header3 {
  background-color: #d9d2e9;
  border-radius: 5px;
  padding: 1px;
}

.app-header-text {
  width: 500px;
}

.lobby-header-text {
  width: 300px;
  font-size: 25px;
}

.game-header-text {
  width: 300px;
  font-size: 25px;
}

.App-link {
  color: #61dafb;
}

.card-img-holder {
  height: 140px;
  width: 212px;
  margin: auto;
}

.card-img-top {
  margin-top: 2px;
  padding: 10px;
}

#player1 {
  margin-top:20px;
  padding: 4px;
  border-radius: 5px;
}

#player2 {
  padding: 2px;
}

#player3 {
  padding: 2px;
  margin: auto;
}

#btn1 {
  padding: 3px;
  border-radius: 5px;
}

#btn2 {
  padding: 2px;
}

#playerPlaceholder {
  margin-top:24px;
  padding: 2px;
  height: 32px;
}

#mainContainer {
  margin-top:20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 100%;
  min-height: 790px;
  min-width: 780px;
}

.game-container-color {
  background-color: #E6E6FA;
}

.lobby-form {
  margin: 10px;
}

#gameBoard {
  width:800px;
  max-width:800px;
  display: inline-block;
}

#appHeaderContainer {
  margin-top:20px;
  padding: 2px;
  width: 700px;
}

.app-text {
  font-size: 15px;
  text-align: justify;
  text-justify: inter-word;
  padding: 10px;
}

#headerContainer1 {
  margin-top:20px;
  padding: 2px;
  width: 750px;
}

#headerContainer2 {
  padding: 2px;
  font-size: 18px;
}

#cardContainer {
  margin-top:40px;
  display: flex;
  height: 290px;
}

#deck1 {
  margin: auto;
  padding: 5px;
  height: 400px;
  width: 270px;
  display: flex;
}

#deck2 {
  margin: auto;
  padding: 5px;
  display: flex;
}

#deck3 {
  margin: auto;
  padding: 5px;
  height: 380px;
  width: 250px;
  display: flex;
}

.lobby-btn-add {
  border-color: #4c1130;
  margin-left: 5px;
}

.lobby-btn-start {
  border-color: #4c1130;
  margin: auto;
  font-size: 30px;
  margin-top: 130px;
}

.lobby-cant-start-message {
  border-color: #4c1130;
  margin: auto;
  margin-left: 30px;
  border-radius: 5px;
  font-size: 20px;
  margin-top: 130px;
}

.player-holder {
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  margin-top: 30px;
  height: 560px;
}

.game-opts-holder {
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  margin-top: 30px;
  margin-left: 30px;
  height: 200px;
}

.lobby-box-title{
  margin-bottom: 20px;
  margin-top: 10px;
}

.lobby-player {
  margin-top: 10px;
  width: 250px;
}

.start-game-holder {
  height: 400px;
}

.game-btn {
  background-color: #c27ba0;
  border-color: #4c1130;
  margin-top: 140px;
  color: white;
}

.game-btn:hover {
  background-color: #a64d79;
  border-color: black;
  color: white;
}

.game-btn:active {
  background-color: #d5a6bd;
  border-color: #4c1130;
  color: white;
}

.next-player-btn {
  background-color: #f6b26b;
  margin-top: 170px;
  color: white;
  border-color: black;
}

.next-player-btn:hover {
  background-color: #f6b26b;
  border-color: black;
  color: white;
}

.next-player-btn:active {
  background-color: #f9cb9c;
  border-color: black;
  color: white;
}

.back-img-centered {
  margin-top: 95px;
}

.card-body-holder1 {
  padding: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: #073763;
}

.card-back-holder1 {
  padding: 4px;
}

.card-body-holder2 {
  width: 230px
}

.card-title-holder {
  border-width: 1px;
  border-style: solid;
  border-color: #073763;
}

.card-type-holder {
  border-width: 1px;
  border-style: solid;
  margin-bottom: 2px;
  border-color: #073763;
}

.card-title {
  margin: auto;
  padding: 5px;
  font-size: 21px;
  font-family: "Comic Sans MS", cursive, sans-serif;
}

.tooltip-content {
  text-align: left;
}

.card-type {
  margin: auto;
  margin-left: 5px;
  text-align: left;
  padding: 5px;
}

.card-tooltip {
  height: 30px;
  margin-top: 2px;
  margin-right: 2px;
  width: 110px;
  font-size: 11px;
}

.status-tooltip {
  padding: 5px;
  border-radius: 5px;
  height: 24px;
  float: right;
  width: 25px;
  font-size: 11px;
}

.card-text-holder {
  border-width: 1px;
  border-style: solid;
  border-color: #073763;
}

.app-para {
  margin-top: 30px; 
}

.card-text {
  padding: 8px;                                  
  padding-top: 15px;                                  
  margin: auto;
  text-align: center;
  text-align: justify;
  font-size: 13px;
  height: 140px;
  font-family: "Trebuchet MS", Helvetica, sans-serif;
}

.card-back-text {
  padding: 15px;
  font-size: 18px;
  height: 110px;
  font-family: "Trebuchet MS", Helvetica, sans-serif;
}


.card-back-title {
  font-size: 50px;
  font-family: "Comic Sans MS", cursive, sans-serif;
}

.start-button {
  border-color: #4c1130;
  margin: auto;
  font-size: 30px;
  margin-top: 100px;
}
