.magenta1 {
  background-color: #ead1dc;
}
.magenta2 {
  background-color: #d5a6bd;
}
.magenta3 {
  background-color: #c27ba0;
}
.magenta4 {
  background-color: #a64d79;
}
.magenta5 {
  background-color: #741b47;
}
.magenta6 {
  background-color: #4c1130;
}

.purple1 {
  background-color: #d9d2e9;
}
.purple2 {
  background-color: #b4a7d6;
}
.purple3 {
  background-color: #8e7cc3;
}
.purple4 {
  background-color: #674ea7;
}
.purple5 {
  background-color: #351c75;
}
.purple6 {
  background-color: #20124d;
}

.blue1 {
  background-color: #cfe2f3;
}
.blue2 {
  background-color: #9fc5e8;
}
.blue3 {
  background-color: #6fa8dc;
}
.blue4 {
  background-color: #3d85c6;
}
.blue5 {
  background-color: #0b5394;
}
.blue6 {
  background-color: #073763;
}

.green1 {
  background-color: #d9ead3;
}
.green2 {
  background-color: #b6d7a8;
}
.green3 {
  background-color: #93c47d;
}
.green4 {
  background-color: #6aa84f;
}
.green5 {
  background-color: #38761d;
}
.green6 {
  background-color: #274e13;
}

.orange1 {
  background-color: #fce5cd;
}
.orange2 {
  background-color: #f9cb9c;
}
.orange3 {
  background-color: #f6b26b;
}
.orange4 {
  background-color: #e69138;
}
.orange5 {
  background-color: #b45f06;
}
.orange6 {
  background-color: #783f04;
}

.red1 {
  background-color: #f4cccc;
}
.red2 {
  background-color: #ea9999;
}
.red3 {
  background-color: #e06666;
}
.red4 {
  background-color: #cc0000;
}
.red5 {
  background-color: #990000;
}
.red6 {
  background-color: #660000;
}